globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"9c8d8ae4d34a89b5eac01fc682323ca86decd6e0"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import sentryBasedConfigs, {
  isSentryConfigAvailable
} from './sentryBasedConfigs';

if (isSentryConfigAvailable) {
  Sentry.init({
    ...sentryBasedConfigs
  });
}
